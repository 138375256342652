import { ReactNode } from 'react';
import { ExternalLink } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { useLocale } from '../../Shared/locale';
import PhoneNumber from '../../Shared/Phone/PhoneNumber';
import { Charity } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import { Icon } from '../../Shared/UI/Icon';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { SharedProps } from '../types';
import CharityLayout from '../UI/CharityLayout';
import Container from '../UI/Container';
import { NavBar } from '../UI/NavBar';

interface HelpPageProps extends SharedProps {
  charity: Charity;
}

export default function HelpPage({
  auth: { user },
  charity,
}: HelpPageProps) {
  const { t } = useTranslation();
  const { locale } = useLocale();

  useCharityStyle(charity);

  return (
    <>
      <NavBar user={user} charity={charity} />

      <Container>
        <div>
          <h1 className="mb-1 text-2xl">
            {t('frontend:need_help')}
          </h1>
          <p>
            {t('frontend:need_help_description', { charity: charity.title })}
          </p>
        </div>

        <Card shadow={false} className="!bg-slate-100">
          <ul className="space-y-1">
            {charity.email && (
              <li>
                <span className="font-medium">{t('frontend:email')}:</span>
                {' '}
                <a href={`mailto:${charity.email}`}>{charity.email}</a>
              </li>
            )}
            {charity.phone && (
              <li>
                <span className="font-medium">{t('frontend:phone')}:</span>
                {' '}
                <a href={`tel:${charity.phone}`}>
                  <PhoneNumber phoneNumber={charity.phone} />
                </a>
              </li>
            )}
            {!charity.email && !charity.phone && (
              <li>
                {t('frontend:no_contact_details_set', { charity: charity.title })}
              </li>
            )}
          </ul>
        </Card>

        <div>
          <h2 className="mb-1 text-xl">
            {t('frontend:frequently_asked_questions')}
          </h2>
          <p>
            {t('frontend:frequently_asked_questions_description')}
            {' '}
            <a
              href={`https://about.supporta.cc/${locale}/helpdesk`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('frontend:view_frequently_asked_questions')}
              <Icon className="ml-1.5">
                <ExternalLink />
              </Icon>
            </a>
          </p>
        </div>
      </Container>
    </>
  );
}

HelpPage.layout = (page: ReactNode) => <CharityLayout>{page}</CharityLayout>;
