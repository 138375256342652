import { Link } from '@inertiajs/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocale } from '../../Shared/locale';
import { Charity } from '../../Shared/types';
import SupportaSymbol from '../../Shared/UI/SupportaSymbol';
import { useRouter } from '../router';
import Container from './Container';
import { EmbedContext } from './EmbedProvider';

export function Footer({
  charity,
}: {
  charity?: Charity | null;
}) {
  const { t } = useTranslation();
  const { routes } = useRouter();
  const { locale } = useLocale();
  const { isEmbedded } = useContext(EmbedContext);

  const linkProps = isEmbedded ? {
    target: '_blank',
    rel: 'noopener noreferrer',
  } : {};

  const LinkComponent = isEmbedded ? 'a' : Link;

  const footerLinkClass = 'm-2 inline-block';

  return (
    <div className="border-t border-slate-200">
      <Container size="lg">
        <div className="text-center">
          <div className="mb-6 text-sm">
            {charity && (
              <>
                <LinkComponent href={routes.home_page()} className={footerLinkClass} {...linkProps}>
                  {t('frontend:all_campaigns')}
                </LinkComponent>
                {' '}
                <LinkComponent href={routes.select_charity_page(charity.public ? charity.slug : null)} className={footerLinkClass} {...linkProps}>
                  {charity.public && t('frontend:raise_money_for_charity', { charity: charity.title })}
                  {!charity.public && t('frontend:raise_money_for_a_charity')}
                </LinkComponent>
                {' '}
                <LinkComponent href={routes.help_page()} className={footerLinkClass} {...linkProps}>
                  {t('frontend:need_help')}
                </LinkComponent>
                {' '}
                <LinkComponent href={routes.privacy_page()} className={footerLinkClass} {...linkProps}>
                  {t('frontend:privacy_statement')}
                </LinkComponent>
                {' '}
                <a href={`https://about.supporta.cc/${locale}/terms`} className={footerLinkClass} {...linkProps}>
                  {t('frontend:terms_of_service')}
                </a>
              </>
            )}
            {!charity && (
              <>
                <div className="font-medium">
                  <LinkComponent href={routes.home_page()} className={footerLinkClass} {...linkProps}>
                    {t('frontend:all_campaigns')}
                  </LinkComponent>
                  {' '}
                  <LinkComponent href={routes.charities_page()} className={footerLinkClass} {...linkProps}>
                    {t('frontend:homepage.all_charities')}
                  </LinkComponent>
                  {' '}
                  <LinkComponent href={routes.events_page()} className={footerLinkClass} {...linkProps}>
                    {t('frontend:homepage.all_events')}
                  </LinkComponent>
                  {' '}
                  <LinkComponent href={routes.select_charity_page()} className={footerLinkClass} {...linkProps}>
                    {t('frontend:raise_money_for_a_charity')}
                  </LinkComponent>
                  {' '}
                  <a href={`https://about.supporta.cc/${locale}/helpdesk`} className={footerLinkClass} {...linkProps}>
                    {t('frontend:need_help')}
                  </a>
                </div>
                {' '}
                <a href={`https://about.supporta.cc/${locale}`} className={footerLinkClass} {...linkProps}>
                  {t('frontend:info_for_charities')}
                </a>
                {' '}
                <a href={`https://about.supporta.cc/${locale}/contact`} className={footerLinkClass} {...linkProps}>
                  {t('frontend:contact')}
                </a>
                {' '}
                <a href={`https://about.supporta.cc/${locale}/privacy`} className={footerLinkClass} {...linkProps}>
                  {t('frontend:privacy_statement')}
                </a>
                {' '}
                <a href={`https://about.supporta.cc/${locale}/terms`} className={footerLinkClass} {...linkProps}>
                  {t('frontend:terms_of_service')}
                </a>
              </>
            )}
          </div>

          <a
            href="https://supporta.cc"
            rel="noopener noreferrer"
            className="text-slate-300 hover:text-emerald-500 transition-colors"
            title="Supporta"
            {...linkProps}
          >
            <SupportaSymbol className="w-4 inline-block" />
          </a>
        </div>
      </Container>
    </div>
  );
}
