import { ReactNode } from 'react';
import { Globe } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { prettifyUrl } from '../../Shared/helpers';
import { useLocale } from '../../Shared/locale';
import { Charity, Pagination } from '../../Shared/types';
import { LinkButton } from '../../Shared/UI/Button';
import { Card } from '../../Shared/UI/Card';
import { Icon } from '../../Shared/UI/Icon';
import { Pager } from '../../Shared/UI/Pager';
import { SearchFilterInput } from '../../Shared/UI/SearchFilterInput';
import SupportaSymbol from '../../Shared/UI/SupportaSymbol';
import { useRouter } from '../router';
import { SharedProps } from '../types';
import Container from '../UI/Container';
import { Footer } from '../UI/Footer';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';

export interface CharitiesPageProps extends SharedProps {
  charities: Pagination<Charity & { funds_raised: number; campaigns_count: number; }>;
}

export default function CharitiesPage({
  charities,
  auth: {
    user,
  },
}: CharitiesPageProps) {
  const { t } = useTranslation();
  const { formatCurrency, formatNumber } = useLocale();
  const { routes } = useRouter();

  return (
    <>
      <NavBar user={user} fixed />

      <Container size="lg" spacing="lg" className="!pt-4">
        <h1 className="text-3xl text-center">
          {t('frontend:homepage.connected_charities')}
        </h1>

        <div className="mx-auto max-w-screen-sm">
          <SearchFilterInput
            inputSize="lg"
            className="!rounded-xl sm:shadow-[0_1px_2px_0_rgb(0_0_0_/_0.05),0_10px_15px_-3px_rgb(0_0_0_/_0.05),_0_2px_4px_-2px_rgb(0_0_0_/_0.05)]"
            aria-label={t('shared:filter.search')}
          />
        </div>

        {charities.meta.total > 0 && (
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {charities.data.map((charity) => (
              <Card
                className="text-center min-w-0 flex flex-col items-stretch"
                spacing="sm"
                panels
                shadow={false}
                border
                key={charity.id}
              >
                <div>
                  <div className="w-24 h-16 mx-auto mb-3">
                    <img src={charity.logo_url || ''} alt={charity.title} className="h-full w-full object-contain" />
                  </div>
                  <h3 className="overflow-hidden whitespace-nowrap text-ellipsis">
                    {charity.title}
                  </h3>
                  <div className="text-slate-500 text-sm">
                    {charity.description}
                  </div>
                  {charity.website && (
                    <div className="text-sm mt-2">
                      <a
                        href={charity.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-slate-500"
                      >
                        <Icon className="mr-1">
                          <Globe />
                        </Icon>
                        {prettifyUrl(charity.website)}
                      </a>
                    </div>
                  )}
                </div>
                <div className="space-y-4 mt-auto">
                  <div className="grid grid-cols-2 gap-2 text-sm">
                    <div>
                      <strong>
                        {formatCurrency(charity.funds_raised, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                      </strong>
                      <br />
                      {t('frontend:raised')}
                    </div>
                    <div>
                      <strong className="">
                        {formatNumber(charity.campaigns_count)}
                      </strong>
                      <br />
                      {t('frontend:campaigns', { count: charity.campaigns_count })}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-2">
                    <LinkButton
                      href={routes.home_page(charity.slug)}
                      className="block w-full"
                      variant="secondary"
                      size="sm"
                    >
                      {t('frontend:view_campaigns')}
                    </LinkButton>
                    <LinkButton
                      href={routes.select_charity_page(charity.slug)}
                      className="block w-full"
                      size="sm"
                    >
                      {t('frontend:start_campaign')}
                    </LinkButton>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        )}

        {charities.meta.total === 0 && (
          <div className="text-center text-xl font-semibold py-16">
            <SupportaSymbol className="text-slate-200 w-12 mx-auto mb-4" />
            {t('frontend:no_results')}
          </div>
        )}

        {charities.meta.last_page > 1 && (
          <Pager data={charities} preserveScroll={false} />
        )}
      </Container>

      <Footer />
    </>
  );
}

CharitiesPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
