import { usePage } from '@inertiajs/react';
import { ReactNode } from 'react';
import { AlertTriangle } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { useMutation } from '../../Shared/api';
import { useLocale } from '../../Shared/locale';
import { useNotifier } from '../../Shared/Notifier/NotificationProvider';
import { Campaign, Donation } from '../../Shared/types';
import { useRouter } from '../router';
import { PageProps } from '../types';
import { Button } from '../UI/Button';
import Layout from '../UI/Layout';

const paymentStatuses = [
  'paid',
  'pending',
  'canceled',
  'expired',
  'failed',
];

export default function TestCheckoutPage() {
  const { t } = useTranslation();
  const { formatCurrency } = useLocale();
  const { routes } = useRouter();
  const { donation } = usePage<PageProps<{ donation: Donation & { campaign: Campaign; }; }>>().props;
  const notifier = useNotifier();

  const [completeCheckout, { loading }] = useMutation(routes.complete_test_checkout(donation.id), {
    onError: () => {
      notifier.notify(t('backend:something_went_wrong'), AlertTriangle);
    },
  });

  return (
    <>
      <div className="max-w-xl p-6 mx-auto space-y-6">
        <h2>
          {'Donating '}
          {formatCurrency(donation.amount)}
          {' to '}
          {donation.campaign.title}
        </h2>

        <h4>Select payment status</h4>

        {paymentStatuses.map((status) => (
          <div key={status}>
            <Button
              onClick={() => completeCheckout({ status })}
              disabled={loading}
              variant="secondary"
              size="sm"
              key={status}
            >
              {t(`shared:payment_status.${status}`)}
            </Button>
          </div>
        ))}
      </div>
    </>
  );
}

TestCheckoutPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
