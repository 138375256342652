import classNames from 'classnames';
import Vapor from 'laravel-vapor';
import { useContext, useEffect, useState } from 'react';
import { User as UserIcon, X } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { EventType } from '../../Shared/eventTracking';
import { useLocale } from '../../Shared/locale';
import { Charity, User } from '../../Shared/types';
import { AButton, Button, LinkButton } from '../../Shared/UI/Button';
import { Icon } from '../../Shared/UI/Icon';
import SupportaSymbol from '../../Shared/UI/SupportaSymbol';
import { useRouter } from '../router';
import Container from './Container';
import { EmbedContext } from './EmbedProvider';

interface NavProps {
  user?: User | null;
  charity?: Charity | null;
  showCampaignButton?: boolean;
  campaignButtonVariant?: 'primary' | 'secondary';
  showLoginButton?: boolean;
}

export const Nav = ({
  user,
  charity,
  showCampaignButton = true,
  campaignButtonVariant,
  showLoginButton = true,
}: NavProps) => {
  const { t } = useTranslation();
  const { routes } = useRouter();
  const { isEmbedded } = useContext(EmbedContext);
  const { locale } = useLocale();

  const loginLink = `/login?redirect_url=${encodeURIComponent(window.location.href)}&locale=${locale}`;

  const closeEmbed = () => {
    window.parent.postMessage({
      type: EventType.WidgetClosed,
    }, '*');
  };

  return (
    <div className="flex justify-between items-center">
      <div>
        {isEmbedded && (
          <SupportaSymbol className="text-slate-400 h-3.5" />
        )}
        {!isEmbedded && (
          <a href="/" className="h-8 flex items-center">
            <img src={Vapor.asset('images/logo.svg')} className="h-3.5" alt="Supporta" />
          </a>
        )}
      </div>
      <div className="flex items-center space-x-2">
        {showLoginButton && user && (
          <AButton
            href={routes.dashoard_page()}
            variant="tertiary"
            size="sm"
          >
            <Icon className="mr-1">
              <UserIcon />
            </Icon>
            {user.first_name}
          </AButton>
        )}

        {showLoginButton && !user && (
          <AButton
            href={loginLink}
            variant="tertiary"
            size="sm"
            target={isEmbedded ? '_blank' : undefined}
            rel={isEmbedded ? 'noreferrer noopener' : undefined}
          >
            <Icon className="mr-2">
              <UserIcon />
            </Icon>
            {t('frontend:log_in')}
          </AButton>
        )}

        {!isEmbedded && showCampaignButton && (!charity || charity?.public) && (
          <LinkButton
            href={routes.select_charity_page(charity?.slug)}
            variant={campaignButtonVariant || 'secondary'}
            size="sm"
          >
            {t('frontend:start_campaign')}
          </LinkButton>
        )}

        {isEmbedded && (
          <Button
            onClick={() => closeEmbed()}
            iconButton
            variant="tertiary"
            size="sm"
            className="!text-2xl text-slate-400 hover:bg-slate-100"
            aria-label={t('frontend:close')}
          >
            <Icon>
              <X strokeWidth={2} />
            </Icon>
          </Button>
        )}
      </div>
    </div>
  );
};

export const NavBar = ({
  user,
  charity,
  fixed = false,
  showCampaignButton = true,
  showLoginButton = true,
}: NavProps & { fixed?: boolean; }) => {
  const { isEmbedded } = useContext(EmbedContext);

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="min-h-14">
      <div
        className={classNames(fixed && !isEmbedded
          ? classNames(scrolled && 'bg-white/90 backdrop-blur-sm border-b border-black/5', 'z-[100] fixed top-0 left-0 right-0 transition-colors')
          : 'shadow-sm relative z-10')}
      >
        <Container size="lg" className="!py-3">
          <Nav
            user={user}
            charity={charity}
            showCampaignButton={showCampaignButton}
            campaignButtonVariant={!charity && (!fixed || scrolled) ? 'primary' : undefined}
            showLoginButton={showLoginButton}
          />
        </Container>
      </div>
    </div>
  );
};
