import { Link } from '@inertiajs/react';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { ChevronLeft } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';

import { Campaign, CampaignType, Charity, Event, Project } from '../../Shared/types';
import { Icon } from '../../Shared/UI/Icon';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import AddCampaignForm from '../Campaign/AddCampaignForm';
import { SharedProps } from '../types';
import CharityLayout from '../UI/CharityLayout';
import Container from '../UI/Container';
import { NavBar } from '../UI/NavBar';

export interface CreateCampaignPageProps extends SharedProps {
  project: Project | null;
  event: Event | null;
  team: Campaign | null;
  teams: Campaign[];
  charity: Charity;
  redirect?: (url: string) => void;
}

export default function CreateCampaignPage({
  project,
  event,
  team,
  teams,
  charity,
  auth: {
    user,
  },
  params,
}: CreateCampaignPageProps) {
  const { t } = useTranslation();

  useCharityStyle(charity);

  const memberId = typeof params.member_id === 'string' ? params.member_id : null;
  const type = params.type === CampaignType.Individual || params.type === CampaignType.Team ? params.type : null;

  const allowCreateCampaign = project ? project.open && !project.ended : event && !event.ended;

  return (
    <>
      <NavBar user={user} charity={charity} showCampaignButton={false} />

      <Container spacing="lg">
        {team && (
          <div>
            <Link href={team.url}>
              <Icon className="mr-1">
                <ChevronLeft />
              </Icon>
              {team.title}
            </Link>
          </div>
        )}

        <div className="flex items-center justify-between">
          <h1 className="text-2xl">
            {t('frontend:start_a_campaign')}
          </h1>
          {params.source === 'create' && (
            <span className="text-sm whitespace-nowrap text-slate-500">
              {t('frontend:step_x_of_y', { x: 3, y: 3 })}
            </span>
          )}
        </div>

        <div className="bg-gradient-to-tl from-project/10 to-project/5 rounded-xl p-6 sm:p-8">
          <div className={classNames((team?.thumbnail_url || charity.logo_url) && 'grid gap-4 items-center grid-cols-[80px_1fr]')}>
            {team?.thumbnail_url && (
              <img src={team.thumbnail_url} className="block w-full rounded-lg shrink-0 aspect-square" />
            )}
            {!team?.thumbnail_url && charity.logo_url && (
              <div className="bg-white shadow-sm rounded-lg p-3 flex justify-center items-center shrink-0 h-full">
                <img src={charity.logo_url} alt={charity.title} className="block w-full" />
              </div>
            )}
            <div className="py-1 space-y-1">
              <h2 className="text-base whitespace-pre-line font-medium leading-snug">
                {team && (
                  <Trans i18nKey="frontend:join_team_name" values={{ team: team.title }}>
                    <strong className="font-extrabold" />
                  </Trans>
                )}
                {!team && (
                  <strong className="font-extrabold">
                    {charity.title}
                  </strong>
                )}
              </h2>
              <div>
                <span className="!bg-project/10 text-sm font-medium inline-flex items-center p-1 leading-tight rounded-sm">
                  {project?.title || event?.title}
                </span>
              </div>
            </div>
          </div>
          {project?.description && (
            <>
              <hr className="my-4 border-project/10" />
              <div className="whitespace-pre-line text-slate-600">
                {project.description}
              </div>
            </>
          )}
        </div>

        {allowCreateCampaign && (
          <AddCampaignForm
            project={project}
            event={event}
            type={type}
            team={team}
            teams={teams}
            memberId={memberId}
            charity={charity}
            user={user}
          />
        )}

        {!allowCreateCampaign && (
          <div className="text-slate-500">
            {t('frontend:project_closed_message')}
          </div>
        )}
      </Container>
    </>
  );
}

CreateCampaignPage.layout = (page: ReactNode) => <CharityLayout>{page}</CharityLayout>;
