import { Link, router } from '@inertiajs/react';
import classNames from 'classnames';
import Vapor from 'laravel-vapor';
import { ReactNode, useState } from 'react';
import { ArrowRight, ArrowRightCircle, Calendar, Check, Globe, Heart, MapPin, Zap } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';

import { prettifyUrl } from '../../Shared/helpers';
import { useLocale } from '../../Shared/locale';
import { Charity, Event, Pagination, PublicCampaign, PublicProject } from '../../Shared/types';
import { LinkButton } from '../../Shared/UI/Button';
import { Card } from '../../Shared/UI/Card';
import DateRange from '../../Shared/UI/DateRange';
import { Icon } from '../../Shared/UI/Icon';
import { Pager } from '../../Shared/UI/Pager';
import Picker, { PickerItem } from '../../Shared/UI/Picker';
import { SearchFilterInput } from '../../Shared/UI/SearchFilterInput';
import SupportaSymbol from '../../Shared/UI/SupportaSymbol';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { useRouter } from '../router';
import { SharedProps } from '../types';
import { AButton, LinkButton as CharityLinkButton } from '../UI/Button';
import Container from '../UI/Container';
import { Footer } from '../UI/Footer';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';
import CampaignCard from './CampaignCard';
import { CampaignerFAQ } from './CampaignerFAQ';

export interface HomePageProps extends SharedProps {
  campaigns: Pagination<PublicCampaign & {
    project: PublicProject;
    event: Event;
    charity: Charity;
  }>;
  fundsRaised: number;
  charities: (PickerItem & { logo_url: string | null; slug: string; })[];
  events: (PickerItem & { slug: string; })[];
  popularEvents: Event[];
  charity: Charity | null;
  event: Event | null;
}

export default function HomePage({
  campaigns,
  fundsRaised,
  charities,
  events,
  popularEvents,
  charity,
  event,
  auth: {
    user,
  },
  params,
}: HomePageProps) {
  const { t } = useTranslation();
  const { locale, formatCurrency, formatNumber } = useLocale();
  const { routes } = useRouter();

  useCharityStyle(charity);

  const [searchInputFocused, setSearchInputFocused] = useState(false);

  const getOverviewUrl = (charityId?: string | null, eventId?: string | null) => {
    const search = params.search ? `?search=${params.search}` : '';

    if (eventId) {
      return `/${locale}/fundraisers/${charityId || 'all'}/${eventId}${search}`;
    }

    if (charityId) {
      return `/${locale}/fundraisers/${charityId}${search}`;
    }

    return `/${locale}${search}`;
  };

  return (
    <>
      <NavBar user={user} fixed />

      <div className="absolute -top-[25px] md:-top-[100px] aspect-video left-0 right-0 z-0 bg-gradient-to-b from-blue-50">
        <div className="w-full h-full bg-gradient-to-t from-white via-white z-10 absolute inset-0"></div>
        <div className="w-1/2 h-full bg-gradient-to-tl from-white/50 z-10 absolute left-0 botom-0 top-0"></div>
        <div className="w-1/2 h-full bg-gradient-to-tr from-white/50 z-10 absolute right-0 botom-0 top-0"></div>
        <SupportaSymbol className="w-full max-w-[800px] mx-auto text-blue-300 opacity-25 relative z-0" />
      </div>

      <Container size="lg" spacing="lg" className="relative z-2">
        <div className="text-center pt-4">
          <div className="block text-base text-slate-500 font-medium mb-2">
            {t('frontend:homepage.your_platform_for')}
          </div>

          <h1 className="text-3xl text-center text-balance mb-4">
            {charity && (
              <Trans i18nKey="frontend:homepage.campaigns_for_specific_charity" values={{ charity: charity.title }}>
                <strong className="font-extrabold" />
              </Trans>
            )}
            {!charity && (
              <Trans i18nKey="frontend:homepage.campaigns_for_charity" >
                <strong className="font-extrabold" />
              </Trans>
            )}
          </h1>

          <div>
            <CharityLinkButton
              href={routes.select_charity_page(charity?.slug)}
            >
              {t('frontend:start_campaign')}
            </CharityLinkButton>
          </div>
        </div>

        <div className="mx-auto space-y-4 max-w-screen-lg sm:pb-8">
          <div className="grid sm:grid-cols-3 space-y-2 sm:space-y-0 sm:flex-row sm:justify-center w-full sm:rounded-xl sm:shadow-[0_1px_2px_0_rgb(0_0_0_/_0.05),0_10px_15px_-3px_rgb(0_0_0_/_0.05),_0_2px_4px_-2px_rgb(0_0_0_/_0.05)] mx-auto">
            <div className={classNames(
              'relative',
              searchInputFocused && 'z-50'
            )}>
              <SearchFilterInput
                inputSize="lg"
                className="sm:rounded-l-xl sm:rounded-r-none"
                onFocus={() => setSearchInputFocused(true)}
                onBlur={() => setSearchInputFocused(false)}
                aria-label={t('shared:filter.search')}
              />
            </div>
            <div className="sm:ml-[-1px]">
              <Picker
                onChange={(item) => router.get(getOverviewUrl(item?.slug, event?.slug))}
                items={charities}
                selected={charity ? charity.id : null}
                icon={<Heart />}
                optional
                placeholder={(t('frontend:homepage.n_charities', {
                  count: charities.length,
                  value: formatNumber(charities.length),
                }))}
                inputSize="lg"
                className="sm:rounded-none"
                aria-label={t('frontend:homepage.select_a_charity')}
              />
            </div>
            <div className="sm:ml-[-1px]">
              <Picker
                onChange={(item) => router.get(getOverviewUrl(charity?.slug, item?.slug))}
                items={events}
                selected={event ? event.id : null}
                icon={<Calendar />}
                optional
                placeholder={(t('frontend:homepage.n_events', {
                  count: events.length,
                  value: formatNumber(events.length),
                }))}
                inputSize="lg"
                className="sm:rounded-l-none sm:rounded-r-xl"
                aria-label={t('frontend:homepage.select_an_event')}
              />
            </div>
          </div>
        </div>

        {charity && (
          <div className="bg-gradient-to-b from-project/10 to-project/5 rounded-xl">
            <div className="grid md:grid-cols-3 gap-0 md:gap-8 rounded-xl items-center">
              <div
                className={classNames(
                  'md:col-span-2 p-6',
                  charity.logo_url && 'grid sm:grid-cols-[120px_1fr] gap-6 items-center',
                )}
              >
                {charity.logo_url && (
                  <div className="bg-white shadow-sm rounded-lg py-6 px-4 flex justify-center items-center shrink-0 sm:h-full">
                    <img src={charity.logo_url} alt={charity.title} className="max-h-12 sm:max-h-16" />
                  </div>
                )}
                <div>
                  <h2 className="text-lg font-bold">
                    {charity.title}
                  </h2>
                  {charity.description}
                  {charity.website && (
                    <div>
                      <a href={charity.website} target="_blank" rel="noopener noreferrer">
                        <Icon className="mr-1">
                          <Globe />
                        </Icon>
                        {prettifyUrl(charity.website)}
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full p-6 md:p-8 border-t md:border-t-0 md:border-l border-project/10 h-full">
                <div className="grid grid-cols-2 mb-3 h-full items-center">
                  <div>
                    <strong className="text-lg whitespace-nowrap">
                      {formatCurrency(fundsRaised, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </strong>
                    <br />
                    {t('frontend:raised')}
                  </div>
                  <div>
                    <strong className="text-lg">
                      {formatNumber(campaigns.meta.total)}
                    </strong>
                    <br />
                    {t('frontend:campaigns', { count: campaigns.meta.total })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {event && (
          <div className="flex items-center flex-wrap md:justify-between text-sm text-slate-500">
            <div className="flex items-center justify-center w-full md:w-auto">
              {event.logo_url && (
                <div className="w-10 h-10 rounded-full bg-white mr-3">
                  <img src={event.logo_url || ''} alt={event.title} className="object-contain h-full w-full rounded-full" />
                </div>
              )}

              <h3 className="text-base font-bold">
                {event.title}
              </h3>
            </div>

            <div className="flex flex-wrap justify-center w-full md:w-auto">
              <div className="mt-2 mx-2 md:ml-4 md:mr-0">
                <Icon className="mr-1">
                  <Calendar />
                </Icon>
                <span>
                  <DateRange start={event.start_date} end={event.end_date} />
                </span>
              </div>
              {event.location && (event.location.id || event.location.description) && (
                <div className="mt-2 mx-2 md:ml-4 md:mr-0">
                  <Icon className="mr-1">
                    <MapPin />
                  </Icon>
                  <span>
                    {event.location.description.split(',')[0]}
                  </span>
                </div>
              )}
              {event.website && (
                <div className="mt-2 mx-2 md:ml-4 md:mr-0">
                  <a href={event.website} target="_blank" rel="noopener noreferrer" className="text-slate-500">
                    <Icon className="mr-1">
                      <Globe />
                    </Icon>
                    {prettifyUrl(event.website)}
                  </a>
                </div>
              )}
            </div>
          </div>
        )}

        {campaigns.meta.total > 0 && (
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {campaigns.data.map((campaign) => (
              <CampaignCard campaign={campaign} key={campaign.id} />
            ))}
          </div>
        )}

        {campaigns.meta.total === 0 && (
          <div className="text-center text-xl font-semibold py-16">
            <SupportaSymbol className="text-slate-200 w-12 mx-auto mb-4" />
            {t('frontend:no_results')}
          </div>
        )}

        {campaigns.meta.last_page > 1 && (
          <Pager data={campaigns} preserveScroll={false} />
        )}
      </Container>

      <hr className="relative z-2" />

      <Container size="lg" spacing="lg" className="relative z-2">
        <div className="sm:text-center max-w-screen mx-auto">
          <h2 className="text-2xl mb-1">
            {t('frontend:homepage.supporta_tag_line')}
          </h2>
          <div className="text-lg text-balance mt-4">
            {t('frontend:homepage.supporta_description')}
          </div>
          <div className="flex sm:justify-center flex-wrap  text-slate-500 mt-6">
            <span className="sm:mx-2 my-1 lg:my-0">
              <Icon className="mr-2">
                <Heart />
              </Icon>
              {t('frontend:homepage.n_charities_connected', {
                count: charities.length,
                value: formatNumber(charities.length),
              })}
            </span>
            <span className="sm:mx-2 my-1 lg:my-0">
              <Icon className="mr-2">
                <ArrowRightCircle />
              </Icon>
              {t('frontend:homepage.donations_straight_to_charity')}
            </span>
            <span className="sm:mx-2 my-1 lg:my-0">
              <Icon className="mr-2">
                <Zap />
              </Icon>
              {t('frontend:homepage.more_than_x_raised')}
            </span>
          </div>
        </div>

        <div className="grid sm:grid-cols-2 gap-8 items-center p-6 sm:p-12 bg-emerald-50 rounded-xl">
          <div className="flex justify-center sm:order-2">
            <img
              src={Vapor.asset('images/website/homepage-step-1.svg')}
              alt={t('frontend:homepage.choose_a_charity')}
              className="sm:-my-4 w-full max-w-[250px] sm:max-w-[400px]"
            />
          </div>
          <div>
            <span className="bg-emerald-100 rounded inline-block py-1 px-2 font-medium text-emerald-600 mr-2 text-xs">
              {t('frontend:homepage.step_n', { step: 1 })}
            </span>
            <h3 className="text-xl mt-3 mb-2">
              {t('frontend:homepage.choose_a_charity')}
            </h3>
            <p>
              {t('frontend:homepage.choose_a_charity_description', { count: charities.length })}
            </p>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 gap-8 items-center p-6 sm:p-12 bg-blue-100 rounded-xl">
          <div className="flex justify-center">
            <img
              src={Vapor.asset('images/website/homepage-step-2.svg')}
              alt={t('frontend:homepage.create_a_campaign')}
              className="sm:-my-4 w-full max-w-[250px] sm:max-w-[400px]"
            />
          </div>
          <div>
            <span className="bg-blue-200 rounded inline-block py-1 px-2 font-medium text-blue-600 mr-2 text-xs">
              {t('frontend:homepage.step_n', { step: 2 })}
            </span>
            <h3 className="text-xl mt-3 mb-2">
              {t('frontend:homepage.create_a_campaign')}
            </h3>
            <p>
              {t('frontend:homepage.create_a_campaign_description')}
            </p>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 gap-8 items-center p-6 sm:p-12 bg-emerald-50 rounded-xl">
          <div className="flex justify-center sm:order-2">
            <img
              src={Vapor.asset('images/website/homepage-step-3.svg')}
              alt={t('frontend:homepage.raise_money')}
              className="sm:-my-4 w-full max-w-[250px] sm:max-w-[400px]"
            />
          </div>
          <div>
            <span className="bg-emerald-100 rounded inline-block py-1 px-2 font-medium text-emerald-600 mr-2 text-xs">
              {t('frontend:homepage.step_n', { step: 3 })}
            </span>
            <h3 className="text-xl mt-3 mb-2">
              {t('frontend:homepage.raise_money')}
            </h3>
            <p>
              {t('frontend:homepage.raise_money_description')}
            </p>
            <p className="mt-6">
              <LinkButton href={routes.select_charity_page()} size="lg">
                {t('frontend:start_campaign')}
              </LinkButton>
            </p>
          </div>
        </div>
      </Container>

      <hr />

      <Container size="lg" spacing="lg">
        <div>
          <h2 className="text-3xl sm:text-center">
            {t('frontend:homepage.connected_charities')}
          </h2>
        </div>
        <div className="grid grid-cols-5 md:grid-cols-10 gap-2 sm:gap-4">
          {charities.filter((charity) => charity.logo_url).slice(0, 10).map((charity) => (
            <Link
              href={getOverviewUrl(charity.slug, event?.slug)}
              className="flex items-center justify-center p-3 sm:py-6 aspect-square shadow-[0_5px_15px_-3px_rgb(0_0_0_/_0.03),_0_4px_6px_-4px_rgb(0_0_0_/_0.05)] rounded-xl"
              key={charity.id}
            >
              <img src={charity.logo_url || ''} alt={charity.title} className="object-contain w-full h-full" />
            </Link>
          ))}
        </div>
        <div className="sm:text-center">
          <Link href={routes.charities_page()} className="font-medium">
            {t('frontend:homepage.all_charities')}
            <Icon className="ml-1">
              <ArrowRight />
            </Icon>
          </Link>
        </div>
      </Container>

      <hr />

      <div className="bgs-slate-50">
        <Container size="lg" spacing="lg">
          <div>
            <h2 className="text-3xl sm:text-center">
              {t('frontend:homepage.popular_events')}
            </h2>
          </div>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-2 sm:gap-4">
            {popularEvents.map((event) => (
              <Link
                href={getOverviewUrl(charity?.slug, event.slug)}
                className="flex items-center p-3 sm:p-4 min-w-0 bg-slate-50 rounded-xl"
                key={event.id}
              >
                <div className="rounded-full w-12 h-12 p-1 bg-white mr-43 shrink-0 shadow-xs mr-3">
                  <img src={event.logo_url || ''} alt={event.title} className="object-contain w-full h-full rounded-full" />
                </div>
                <div className="text-slate-500 min-w-0">
                  <h3 className="font-medium overflow-hidden whitespace-nowrap text-ellipsis">
                    {event.title}
                  </h3>
                  <div className="text-sm">
                    <Trans
                      i18nKey="frontend:progress_raised"
                      values={{ amount: formatCurrency(event.funds_raised) }}
                    >
                      <span />
                    </Trans>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="sm:text-center">
            <Link href={routes.events_page()} className="font-medium">
              {t('frontend:homepage.all_events')}
              <Icon className="ml-1">
                <ArrowRight />
              </Icon>
            </Link>
          </div>
        </Container>
      </div>
      <hr />

      <Container size="lg" spacing="lg">
        <div className="grid md:grid-cols-3 gap-4 sm:gap-8">
          <div>
            <h3 className="text-lg">
              {t('frontend:homepage.frequently_asked_questions')}
            </h3>
          </div>
          <div className="md:col-span-2">
            <CampaignerFAQ showCreateButton />
          </div>
        </div>
      </Container>

      <hr />

      <Container size="lg" spacing="lg">
        <div className="grid md:grid-cols-3 gap-4 sm:gap-8">
          <div>
            <h3 className="text-lg">
              {t('frontend:homepage.for_charities')}
            </h3>
          </div>
          <div className="md:col-span-2">
            <Card className="!bg-blue-100 overflow-hidden text-slate-800" shadow={false} border={false}>
              <SupportaSymbol className="w-[500px] absolute left-1/2 top-[-50px] text-blue-200/30 z-0" />

              <div className="relative z-1">
                <h3 className="flex items-center text-xl mb-2">
                  {t('frontend:homepage.receive_donations_with_supporta')}
                </h3>
                <p className="flex space-x-2 mb-1">
                  <Icon className="text-emerald-500 shrink-0 mt-1">
                    <Check/>
                  </Icon>
                  <span>
                    {t('frontend:homepage.receive_donations_with_supporta_description')}
                  </span>
                </p>
                <p className="flex space-x-2 mb-1">
                  <Icon className="text-emerald-500 shrink-0 mt-1">
                    <Check/>
                  </Icon>
                  <span>
                    {t('frontend:homepage.connect_with_sports_events')}
                  </span>
                </p>
                <p className="flex space-x-2 mb-3">
                  <Icon className="text-emerald-500 shrink-0 mt-1">
                    <Check/>
                  </Icon>
                  <span>
                    {t('frontend:homepage.connect_with_sports_events_description')}
                  </span>
                </p>
                <AButton
                  href="https://about.supporta.cc"
                  className="!bg-blue-600 !border-blue-600 hover:!bg-blue-500 focus:!ring-blue-600/10"
                >
                  {t('frontend:homepage.about_supporta')}
                  <Icon className="ml-1">
                    <ArrowRight />
                  </Icon>
                </AButton>
              </div>
            </Card>
          </div>
        </div>
      </Container>

      <Footer />
    </>
  );
}

HomePage.layout = (page: ReactNode) => <Layout>{page}</Layout>;
