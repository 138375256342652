import { Link } from '@inertiajs/react';
import classNames from 'classnames';
import Vapor from 'laravel-vapor';
import { ReactNode } from 'react';
import { ChevronRight, Edit, ExternalLink } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';

import { useLocale } from '../../Shared/locale';
import { Charity, Event, Pagination } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import { Icon } from '../../Shared/UI/Icon';
import { Pager } from '../../Shared/UI/Pager';
import { SearchFilterInput } from '../../Shared/UI/SearchFilterInput';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import EventHeader from '../Event/EventHeader';
import { useRouter } from '../router';
import { SharedProps } from '../types';
import { LinkButton } from '../UI/Button';
import Container from '../UI/Container';
import { Footer } from '../UI/Footer';
import Header from '../UI/Header';
import Layout from '../UI/Layout';
import { NavBar } from '../UI/NavBar';
import { CampaignerFAQ } from './CampaignerFAQ';

export interface SelectCharityPageProps extends SharedProps {
  event: (Event & {
    funds_raised: number;
    campaigns_count: number;
    charities_count: number;
  }) | null;
  charities: Pagination<Charity>;
  events: Pagination<Event>;
}

export default function SelectCharityPage({
  charity,
  event,
  charities,
  events,
  auth: {
    user,
  },
}: SelectCharityPageProps) {
  const { t } = useTranslation();
  const { routes } = useRouter();
  const { locale } = useLocale();

  useCharityStyle(charity);

  return (
    <>
      <NavBar user={user} showCampaignButton={false} />

      {event && (
        <Header className="bg-slate-50">
          <Container>
            <EventHeader event={event} />
          </Container>
        </Header>
      )}

      <Container spacing="lg">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl">
            {t('frontend:start_a_campaign')}
          </h1>
          <span className="text-sm whitespace-nowrap text-slate-500">
            {t('frontend:step_x_of_y', { x: charity ? 2 : 1, y: 3 })}
          </span>
        </div>

        {charity && (
          <div className="bg-gradient-to-tl from-project/10 to-project/5 rounded-lg p-6 sm:p-8 relative">
            <Link
              href={routes.select_charity_page(null, { eventId: event?.id })}
              className="absolute top-6 right-6 sm:top-8 sm:right-8 text-slate-500 hover:text-slate-800 block p-1"
              title={t('frontend:select_another_charity')}
            >
              <Icon>
                <Edit />
              </Icon>
            </Link>
            <div className={classNames(charity.logo_url && 'grid gap-4 items-center grid-cols-[80px_1fr]')}>
              {charity.logo_url && (
                <div className="bg-white shadow-sm rounded-lg p-3 flex justify-center items-center shrink-0 h-full">
                  <img src={charity.logo_url} alt={charity.title} className="block w-full" />
                </div>
              )}
              <div className="py-1">
                <h2 className="text-base whitespace-pre-line font-medium leading-snug">
                  <Trans i18nKey="frontend:selected_charity" values={{ charity: charity.title }}>
                    <strong className="font-extrabold" />
                  </Trans>
                </h2>
              </div>
            </div>
            {charity.description && (
              <>
                <hr className="my-4 border-project/10" />
                <div className="text-slate-600 mt-1">
                  {charity.description}
                </div>
              </>
            )}
          </div>
        )}

        {!charity && (
          <>
            <div>
              <div className="p-4 sm:p-8 bg-blue-100 rounded-xl">
                <img
                  src={Vapor.asset('images/website/homepage-step-2.svg')}
                  alt={t('frontend:homepage.create_a_campaign')}
                  className="max-w-[250px] mx-auto -mb-2"
                />
              </div>
            </div>
            <div className="space-y-4">
              <div>
                <h3 className="text-base">
                  {t('frontend:select_your_charity')}
                </h3>
                <p>
                  {t('frontend:select_your_charity_description')}
                </p>
              </div>

              <div>
                <SearchFilterInput
                  id="search"
                  inputSize="lg"
                  className="!rounded-lg"
                  placeholder={t('frontend:find_your_charity')}
                />
              </div>

              <div className="space-y-2">
                {charities.data.map((charity) => (
                  <CharityCard charity={charity} event={event} key={charity.id} />
                ))}
              </div>

              {charities.data.length === 0 && (
                <div className="text-slate-500">
                  {t('frontend:no_results')}
                </div>
              )}
            </div>

            {charities.meta.last_page > 1 && (
              <Pager data={charities} preserveScroll={false} />
            )}
          </>
        )}

        {charity && (
          <>
            <div className="space-y-4">
              <div>
                <h3 className="text-base">
                  {t('frontend:start_generic_campaign')}
                </h3>
                <p>
                  {t('frontend:start_generic_campaign_description', { charity: charity.title })}
                </p>
              </div>

              <LinkButton
                href={routes.select_charity_page(charity.slug, { eventId: 'default' })}
                className="rounded-lg w-full"
                size="lg"
              >
                {t('frontend:start_campaign')}
                <Icon className="ml-2 opacity-80">
                  <ChevronRight />
                </Icon>
              </LinkButton>
            </div>

            <div className="pt-[10px]">
              <div className="border-t border-slate-200 flex justify-center">
                <div className="bg-white mt-[-10px] px-4 text-sm">
                  {t('frontend:or')}
                </div>
              </div>
            </div>
            <div className="space-y-4">

              <div>
                <h3 className="text-base">
                  {t('frontend:participating_in_event')}
                </h3>
                <p>
                  {t('frontend:participating_in_event_description')}
                </p>
              </div>

              <div>
                <SearchFilterInput
                  id="search"
                  inputSize="lg"
                  className="!rounded-lg"
                  placeholder={t('frontend:find_your_event')}
                />
              </div>

              {events.data.length > 0 && (
                <div className="space-y-2">
                  {events.data.map((event) => (
                    <EventCard charity={charity} event={event} key={event.id} />
                  ))}
                </div>
              )}
            </div>

            {events.data.length === 0 && (
              <div className="text-slate-500">
                {t('frontend:no_results')}
              </div>
            )}

            {events.meta.last_page > 1 && (
              <Pager data={events} preserveScroll={false} />
            )}
          </>
        )}

        {!charity && (
          <div className="text-center">
            <a
              href={`https://about.supporta.cc/${locale}/submit-charity`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('frontend:charity_missing')}
              <Icon className="ml-1">
                <ExternalLink />
              </Icon>
            </a>
          </div>
        )}
      </Container>

      <hr />

      <div className="bg-slate-50">
        <Container spacing="lg">
          <h3 className="font-medium text-slate-500">
            {t('frontend:homepage.frequently_asked_questions')}
          </h3>
          <CampaignerFAQ />
        </Container>
      </div>

      <Footer />
    </>
  );
}

SelectCharityPage.layout = (page: ReactNode) => <Layout>{page}</Layout>;

const CharityCard = ({
  charity,
  event,
}: {
  charity: Charity,
  event: Event | null,
}) => {
  const { routes } = useRouter();

  return (
    <Card
      spacing="xs"
      link={{
        href: routes.select_charity_page(charity.slug, { eventId: event?.id }),
        ariaLabel: charity.title,
      }}
      border
      className="flex items-center gap-4 !py-3 !rounded-lg"
    >
      <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 p-1 rounded">
        {charity.logo_url && (
          <img src={charity.logo_url} alt={charity.title} className="block object-contain w-full h-full" />
        )}
        {!charity.logo_url && (
          <span className="block w-10 h-10 rounded-full leading-[2.5rem] text-center bg-blue-300 text-white">
            {charity.title.slice(0, 1)}
          </span>
        )}
      </div>
      <div className="min-w-0">
        <div className="flex items-center gap-2">
          <h4 className="overflow-hidden whitespace-nowrap text-ellipsis">
            {charity.title}
          </h4>
          <Icon className="text-slate-400">
            <ChevronRight />
          </Icon>
        </div>
        <div className="w-full text-slate-500 line-clamp-2">
          {charity.description}
        </div>
      </div>
    </Card>
  );
};

const EventCard = ({
  charity,
  event,
}: {
  charity: Charity | null,
  event: Event,
}) => {
  const { routes } = useRouter();

  return (
    <Card
      spacing="xs"
      link={{
        href: routes.select_charity_page(charity?.slug, { eventId: event.id }),
        ariaLabel: event.title,
      }}
      border
      className="flex items-center gap-4 !py-3 !rounded-lg"
    >
      <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 p-1">
        {event.logo_url && (
          <img src={event.logo_url} alt={event.title} className="block object-contain w-full h-full" />
        )}
        {!event.logo_url && (
          <span className="block w-10 h-10 rounded-full leading-[2.5rem] text-center bg-blue-300 text-white">
            {event.title.slice(0, 1)}
          </span>
        )}
      </div>
      <div className="min-w-0">
        <div className="flex items-center gap-2">
          <h4 className="overflow-hidden whitespace-nowrap text-ellipsis">
            {event.title}
          </h4>
          <Icon className="text-slate-400">
            <ChevronRight />
          </Icon>
        </div>
      </div>
    </Card>
  );
};
