
import { EventType, NewCampaignPayload, NewDonationPayload } from './eventTracking';
import { useEventHandler } from './useEventHandler';

export function useGaEvents(measurementId?: string | null) {
  useEventHandler(EventType.NewCampaign, (data: object) => {
    if (!measurementId) {
      return;
    }

    const payload = data as NewCampaignPayload;

    window.gtag?.('event', 'supporta_new_campaign', {
      charity: payload.charity.title,
      project: payload.project.title,
      send_to: measurementId,
    });
  });

  useEventHandler(EventType.NewDonation, (data: object) => {
    if (!measurementId) {
      return;
    }

    const payload = data as NewDonationPayload;

    // Clear the previous ecommerce object to prevent multiple ecommerce events on a page from affecting each other.
    window.dataLayer?.push({ ecommerce: null });

    window.gtag?.('event', 'purchase', {
      transaction_id: payload.donation.id,
      currency: 'EUR',
      value: payload.donation.amount / 100,
      items: [{
        item_id: `donations_${payload.project.id}`,
        item_name: `Donations | ${payload.project.title}`,
        item_brand: payload.charity.title,
        item_category: 'Donations',
        currency: 'EUR',
        price: payload.donation.amount / 100,
        affiliation: 'Supporta',
      }],
      send_to: measurementId,
    });
  });
}
