import { usePage } from '@inertiajs/react';
import { ReactNode } from 'react';

import { PageProps } from '../types';
import { Footer } from './Footer';
import DefaultLayout from './Layout';

export default function CharityLayout({
  children,
}: {
  children?: ReactNode;
}) {
  const { props: { charity } } = usePage<PageProps>();

  return (
    <DefaultLayout>
      {children}
      <Footer charity={charity} />
    </DefaultLayout>
  );
}
