import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Charity } from '../../Shared/types';
import { Card } from '../../Shared/UI/Card';
import { HTML } from '../../Shared/UI/HTML';
import { useCharityStyle } from '../../Shared/useCharityStyle';
import { SharedProps } from '../types';
import CharityLayout from '../UI/CharityLayout';
import Container from '../UI/Container';
import { NavBar } from '../UI/NavBar';

interface PrivacyPageProps extends SharedProps {
  charity: Charity;
}

export default function PrivacyPage({
  auth: { user },
  charity,
}: PrivacyPageProps) {
  const { t } = useTranslation();

  useCharityStyle(charity);

  return (
    <>
      <NavBar user={user} charity={charity} />

      <Container>
        <div>
          <h1 className="mb-1 text-2xl">
            {t('frontend:privacy_statement')}
          </h1>
          <p>
            {t('frontend:privacy_intro', { charity: charity.title })}
          </p>
        </div>
        <Card shadow={false} className="!bg-slate-100">
          <HTML
            value={
              charity.privacy_statement || t('frontend:no_privacy_statement_set', { charity: charity.title })
            }
          />
        </Card>
      </Container>
    </>
  );
}

PrivacyPage.layout = (page: ReactNode) => <CharityLayout>{page}</CharityLayout>;
