import classNames from 'classnames';
import { useState } from 'react';
import { ChevronDown, ChevronRight, ExternalLink } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';

import { useLocale } from '../../Shared/locale';
import { LinkButton } from '../../Shared/UI/Button';
import { Card } from '../../Shared/UI/Card';
import { Icon } from '../../Shared/UI/Icon';
import { useRouter } from '../router';

export const CampaignerFAQ = ({
  showCreateButton,
}: {
  showCreateButton?: boolean;
}) => {
  const { t } = useTranslation();
  const { routes } = useRouter();
  const { locale } = useLocale();

  const [expanded, setExpanded] = useState({
    what_is_supporta: false,
    which_charities_can_i_support: false,
    where_does_money_go: false,
    how_to_start_campaign: false,
  });

  const toggle = (key: keyof typeof expanded) => setExpanded((expanded) => ({
    ...expanded,
    [key]: !expanded[key],
  }));

  return (
    <Card className="relative overflow-hidden !bg-white border-slate-200 space-y-6" shadow={false} border>
      <div>
        <h4 className="text-lg font-bold">
          <a onClick={() => toggle('what_is_supporta')} role="button" className="flex text-slate-800">
            <Icon className="mt-[5px] mr-2">
              {expanded.what_is_supporta ? <ChevronDown /> : <ChevronRight />}
            </Icon>
            <span>
              {t('frontend:homepage.what_is_supporta')}
            </span>
          </a>
        </h4>
        <div className={classNames('mt-3 space-y-3 text-slate-600', !expanded.what_is_supporta && 'hidden')}>
          <p>
            {t('frontend:homepage.what_is_supporta_description')}
          </p>
        </div>
      </div>
      <hr />
      <div>
        <h4 className="text-lg font-bold">
          <a onClick={() => toggle('which_charities_can_i_support')} role="button" className="flex text-slate-800">
            <Icon className="mt-[5px] mr-2">
              {expanded.which_charities_can_i_support ? <ChevronDown /> : <ChevronRight />}
            </Icon>
            <span>
              {t('frontend:homepage.which_charities_can_i_support')}
            </span>
          </a>
        </h4>
        <div className={classNames('mt-3 space-y-3 text-slate-600', !expanded.which_charities_can_i_support && 'hidden')}>
          <p>
            <Trans i18nKey="frontend:homepage.which_charities_can_i_support_description" />
          </p>
          {showCreateButton && (
            <p className="mt-3">
              <LinkButton href={routes.charities_page()} variant="secondary">
                {t('frontend:homepage.view_all_charities')}
              </LinkButton>
            </p>
          )}
          <p>
            <Trans i18nKey="frontend:homepage.charity_missing_description" />
            {' '}
            <a href={`https://about.supporta.cc/${locale}/submit-charity`} target="_blank" rel="noopener noreferrer">
              {t('frontend:homepage.charity_missing_link')}
              <Icon className="ml-1">
                <ExternalLink />
              </Icon>
            </a>
          </p>
        </div>
      </div>
      <hr />
      <div>
        <h4 className="text-lg font-bold">
          <a onClick={() => toggle('where_does_money_go')} role="button" className="flex text-slate-800">
            <Icon className="mt-[5px] mr-2">
              {expanded.where_does_money_go ? <ChevronDown /> : <ChevronRight />}
            </Icon>
            <span>
              {t('frontend:homepage.where_does_money_go')}
            </span>
          </a>
        </h4>
        <div className={classNames('mt-3 space-y-3 text-slate-600', !expanded.where_does_money_go && 'hidden')}>
          <p>
            <Trans i18nKey="frontend:homepage.where_does_money_go_description">
              <strong />
            </Trans>
          </p>
        </div>
      </div>
      <hr />
      <div>
        <h4 className="text-lg font-bold">
          <a onClick={() => toggle('how_to_start_campaign')} role="button" className="flex text-slate-800">
            <Icon className="mt-[5px] mr-2">
              {expanded.how_to_start_campaign ? <ChevronDown /> : <ChevronRight />}
            </Icon>
            <span>
              {t('frontend:homepage.how_to_start_campaign')}
            </span>
          </a>
        </h4>
        <div className={classNames('mt-3 space-y-3 text-slate-600', !expanded.how_to_start_campaign && 'hidden')}>
          <p>
            {t('frontend:homepage.how_to_start_campaign_description')}
          </p>
          {showCreateButton && (
            <p className="mt-3">
              <LinkButton href={routes.select_charity_page()}>
                {t('frontend:start_campaign')}
              </LinkButton>
            </p>
          )}
        </div>
      </div>
      <hr />
      <div>
        <a href={`https://about.supporta.cc/${locale}/helpdesk`} target="_blank" rel="noreferrer">
          <Icon className="mr-2">
            <ExternalLink />
          </Icon>
          {t('frontend:homepage.more_faqs')}
        </a>
      </div>
    </Card>
  );
};

